.main {
  background-image: var(--background-gradient);
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10%;
  height: 100vh;
  width: 100vw;
}

.image {
  height: 100vh;
  width: 100vw;
  object-fit: cover;
  position: absolute;
}

.box {
  background: #fff;
  height: 100vh;
  border-radius: 0px 20% 0px 0px;
  display: flex;
  justify-content: center;
}

@media (max-width: 576px) {
  .main {
    justify-content: center;
  }

  .box {
    border-radius: 0 184px 0px 0px;
  }
}