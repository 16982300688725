.select {
  :global {
    .ant-select-selector {
      background: var(--input-background);
      font-family: Roboto, Inter;
      font-weight: var(--input-font-weight);

      height: var(--input-height) !important;

      font-size: var(--input-font-size);
      line-height: var(--input-line-height);

      padding: var(--input-padding);

      border: var(--input-border) var(--input-border-style) var(--input-border-color);
      border-radius: var(--input-border-radius);
    }

    .ant-select-selection-item {
      line-height: var(--input-height) !important;
    }
  }
}
