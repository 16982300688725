.float-label {
  position: relative;
  transition: 0.2s ease all;
}

.input {
  transition: 0.2s ease all;
}

.label {
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 12px;
  top: 1.43229vh;
  transition: 0.2s ease all;
}

.as-placeholder {
  color: #898889;
}

.as-label {
  top: -1.04166vh;
  font-size: 12px !important;
  background: #1f1f1f;
  padding: 0 0.3125vw;
  margin-left: -0.3125vw;
  color: #898889;
}
