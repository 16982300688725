.field {
  margin-bottom: var(--margin) !important;

  label {
    font-size: var(--regular-font-size);
    line-height: var(--regular-line-height);
    font-weight: var(--regular-font-weight);
    flex-direction: row-reverse;
    gap: var(--input-gap-required);
    margin-left: var(--margin) !important;
    color: var(--input-label-color) !important;
  }

  :global {
    .ant-upload-wrapper.ant-upload-picture-circle-wrapper
      .ant-upload-list.ant-upload-list-picture-circle
      .ant-upload-list-item-container,
    .ant-upload-wrapper.ant-upload-picture-circle-wrapper
      .ant-upload.ant-upload-select {
      font-size: var(--regular-font-size);
      line-height: var(--regular-line-height);
      font-weight: var(--regular-font-weight);
    }
  }
}
