.box {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  grid-gap: 1rem;
}

.upload-message {
  font-size: var(--medium-font-size);
  line-height: var(--medium-line-height);
  font-weight: var(--medium-line-height);
  color: #00000073;
  width: auto;
}
