@font-face {
  font-family: "CabinetGrotesk-Variable";
  src: url("../public/fonts/CabinetGrotesk-Variable.woff2") format("woff2"),
    url("../public/fonts/CabinetGrotesk-Variable.woff") format("woff"),
    url("../public/fonts/CabinetGrotesk-Variable.ttf") format("truetype");
  font-weight: 100 900;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "CabinetGrotesk-Thin";
  src: url("../public/fonts/CabinetGrotesk-Thin.woff2") format("woff2"),
    url("../public/fonts/CabinetGrotesk-Thin.woff") format("woff"),
    url("../public/fonts/CabinetGrotesk-Thin.ttf") format("truetype");
  font-weight: 100;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "CabinetGrotesk-Extralight";
  src: url("../public/fonts/CabinetGrotesk-Extralight.woff2") format("woff2"),
    url("../public/fonts/CabinetGrotesk-Extralight.woff") format("woff"),
    url("../public/fonts/CabinetGrotesk-Extralight.ttf") format("truetype");
  font-weight: 200;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "CabinetGrotesk-Light";
  src: url("../public/fonts/CabinetGrotesk-Light.woff2") format("woff2"),
    url("../public/fonts/CabinetGrotesk-Light.woff") format("woff"),
    url("../public/fonts/CabinetGrotesk-Light.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "CabinetGrotesk-Regular";
  src: url("../public/fonts/CabinetGrotesk-Regular.woff2") format("woff2"),
    url("../public/fonts/CabinetGrotesk-Regular.woff") format("woff"),
    url("../public/fonts/CabinetGrotesk-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "CabinetGrotesk-Medium";
  src: url("../public/fonts/CabinetGrotesk-Medium.woff2") format("woff2"),
    url("../public/fonts/CabinetGrotesk-Medium.woff") format("woff"),
    url("../public/fonts/CabinetGrotesk-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "CabinetGrotesk-Bold";
  src: url("../public/fonts/CabinetGrotesk-Bold.woff2") format("woff2"),
    url("../public/fonts/CabinetGrotesk-Bold.woff") format("woff"),
    url("../public/fonts/CabinetGrotesk-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "CabinetGrotesk-Extrabold";
  src: url("../public/fonts/CabinetGrotesk-Extrabold.woff2") format("woff2"),
    url("../public/fonts/CabinetGrotesk-Extrabold.woff") format("woff"),
    url("../public/fonts/CabinetGrotesk-Extrabold.ttf") format("truetype");
  font-weight: 800;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "CabinetGrotesk-Black";
  src: url("../public/fonts/CabinetGrotesk-Black.woff2") format("woff2"),
    url("../public/fonts/CabinetGrotesk-Black.woff") format("woff"),
    url("../public/fonts/CabinetGrotesk-Black.ttf") format("truetype");
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}

:root {
  --primary-color: #032881;
  --secundary-color: #02143f;
  --purple-dark: #02143f;

  --label-color: #032881;

  --text-color: #032881;

  --title-color: #032881;
  --title-color-dark: #ffffff;

  --border-radius: 0.8rem;
  --border-color: rgba(0, 0, 0, 0.1);
  --box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;

  --background-gradient: radial-gradient(
    circle at 50% -20.71%,
    #032881 0,
    #032064 25%,
    #02143f 50%,
    #010c25 75%,
    #000000 100%
  );

  --neutral-color-1: #ffffff;
  --neutral-color-2: #fafafa;
  --neutral-color-3: #f5f5f5;
  --neutral-color-4: #f0f0f0;
  --neutral-color-5: #d9d9d9;
  --neutral-color-6: #bfbfbf;
  --neutral-color-7: #8c8c8c;
  --neutral-color-8: #595959;
  --neutral-color-9: #434343;
  --neutral-color-10: #262626;
  --neutral-color-11: #1f1f1f;
  --neutral-color-12: #141414;
}
