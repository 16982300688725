.root-table {
  :global {
    .ant-table {
      overflow-x: auto;
    }

    .ant-table-container {
        min-width: 800px;
      }
  }
}
