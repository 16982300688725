.content {
  border-radius: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: 3rem;
  padding-inline: 2rem;
  flex-direction: column;
  gap: 4rem;
  max-width: 350px;
}

.form {
  width: 100%;
}

.button {
  width: 100%;
  margin-top: 1rem;
}

.logo {
  width: 200px;
}

.footer {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 0.5rem;

  .or {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 1rem;
    color: var(--purple-dark);

    .line {
      width: 100%;
      background: var(--purple-dark);
    }
  }
}

.full-width {
  position: fixed;
  top: 0;
  left: 0;
}

@media (max-width: 426px) {
  .content {
    border-radius: 1.5rem 1.5rem 0 0;
  }
}
