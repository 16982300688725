@import "./variables";

* {
  font-family: "Roboto", "Times New Roman", Times, serif;
}

/* Cor de fundo do autocomplete */
input:-webkit-autofill,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 30px #fff inset !important;
  box-shadow: 0 0 0 30px #fff inset !important;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  margin-right: 4px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(87, 87, 114, 0.18);
  border-radius: 4px;
}
