.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    color: #1890FF;
    animation: svg-animate 4s linear infinite;
    max-width: calc(100vw - 100px);
    text-align: center;
  }

  .image {
    max-width: calc(100vw - 100px);
  }
}

@keyframes svg-animate {
  0% {
    color: #1890FF;
  }
  20% {
    color: #f4801a;
  }
  40% {
    color: #52c41a;
  }
  60% {
    color: #1890ff;
  }
  80% {
    color: #ed4192;
  }
  100% {
    color: #003e79;
  }
}