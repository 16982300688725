.content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.image {
  width: 45px;
}

.logo {
  font-family: "CabinetGrotesk-Bold";
  font-size: 28px;
  line-height: 30px;
  margin: 0;
}

.logo-light {
  font-family: "CabinetGrotesk-Light";
  font-size: 20px;
  line-height: 22px;
  margin: 0;
}