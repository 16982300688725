.box-avatar {
  background: var(--neutral-color-6);
  border-radius: 100%;
  position: relative;

  overflow: hidden;
  cursor: pointer;
}

.box-avatar .edit {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
}

.box-avatar:hover .edit {
  display: inherit;
}

.box-avatar:hover .avatar-hover {
  opacity: 0.6;
}
