.main {
  background-image: var(--background-gradient);
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10%;
  height: 100vh;
  width: 100vw;
}

.image {
  width: 40%;
  height: 50%;
}

@media (max-width: 945px) {
  .image {
    display: none;
  }
}

@media (max-width: 426px) {
  .main {
    align-items: end;
  }
}
